import {
	SearchOutlined,
	EditOutlined,
	DeleteOutlined,
	UploadOutlined,
	DownloadOutlined,
	LoginOutlined,
	ClearOutlined,
	LockOutlined,
	SnippetsOutlined,
	AreaChartOutlined,
	DiffOutlined,
	FileZipOutlined,
	TeamOutlined,
	MailOutlined,
	PlusCircleOutlined,
	RollbackOutlined,
	SaveOutlined,
	FileSearchOutlined,
	IssuesCloseOutlined,
	ReloadOutlined,
	ShoppingCartOutlined,
	InfoCircleOutlined,
	UserAddOutlined
} from '@ant-design/icons';

export const Icon = {
	dashboard: () => <AreaChartOutlined />,
	document: () => <SnippetsOutlined />,
	documentExport: () => <DownloadOutlined />,
	historySignDoc: () => <DiffOutlined />,
	zipXml: () => <FileZipOutlined />,
	manageUser: () => <TeamOutlined />,
	configMail: () => <MailOutlined />,
	taxReport: () => <FileSearchOutlined />,
	missingDoc: () => <IssuesCloseOutlined />,
	purchaseHistory: () => <ShoppingCartOutlined />,
	
	/**most for button */
	search: () => <SearchOutlined />,
	edit: () => <EditOutlined />,
	remove: () => <DeleteOutlined />,
	clear: () => <ClearOutlined />,
	upload: () => <UploadOutlined />,
	download: () => <DownloadOutlined />,
	login: () => <LoginOutlined />,
	forgetPassword: () => <LockOutlined />,
	create: () => <PlusCircleOutlined />,
	back: () => <RollbackOutlined />,
	save: () => <SaveOutlined />,
	reAct: () => <ReloadOutlined />,
	recheck: () => <InfoCircleOutlined />,
	register: () => <UserAddOutlined />,
}

export const IconMenu = {
	dashboard: <AreaChartOutlined />,
	document: <SnippetsOutlined />,
	documentExport: <DownloadOutlined />,
	historySignDoc: <DiffOutlined />,
	zipXml: <FileZipOutlined />,
	manageUser: <TeamOutlined />,
	configMail: <MailOutlined />,
	taxReport: <FileSearchOutlined />,
	missingDoc: <IssuesCloseOutlined />,
	receiptHistory: <SnippetsOutlined />,
	purchaseHistory: <ShoppingCartOutlined />,

}
