/**
 * 
 * LoginAdminForm --> เข้าสู่ระบบ admin
 */
export const LoginAdminForm = () => {
	return [
		{
			key: '1',
			span: 24,
			disabled: false,
			name: 'username',
			label: 'ชื่อผู้ใช้งาน',
			type: 'INPUT',
			value: '',
			defaultValue: '',
			rules: { required: true }
		},
		{
			key: '2',
			span: 24,
			disabled: false,
			name: 'password',
			label: 'รหัสผ่าน',
			type: 'INPUT_PASSWORD',
			value: '',
			defaultValue: '',
			rules: {
				required: true,
				maxLength: {
					value: 30,
					message: 30
				},
			}
		},
	]
}

/**
 * 
 * LoginForm --> เข้าสู่ระบบ
 */
export const LoginForm = () => {
	return [
		{
			key: '1',
			span: 24,
			disabled: false,
			name: 'username',
			label: 'เลขประจำตัวผู้เสียภาษี/ชื่อผู้ใช้งาน',
			type: 'INPUT',
			value: '',
			defaultValue: '',
			rules: { required: true }
		},
		{
			key: '2',
			span: 24,
			disabled: false,
			name: 'password',
			label: 'รหัสผ่าน',
			type: 'INPUT_PASSWORD',
			value: '',
			defaultValue: '',
			rules: {
				required: true,
				maxLength: {
					value: 30,
					message: 30
				},
			}
		},
	]
}


/**
 * 
 * ForgetPasswordAdminForm --> ลืมรหัสผ่าน admin
 */
export const ForgetPasswordAdminForm = () => {
	return [
		{
			key: '1',
			span: 24,
			disabled: false,
			name: 'username',
			label: 'ชื่อผู้ใช้งาน',
			type: 'INPUT',
			value: '',
			defaultValue: '',
			rules: { required: true }
		},
		{
			key: '2',
			span: 24,
			disabled: false,
			name: 'email',
			label: 'อีเมลสำหรับส่งรหัสผ่านใหม่',
			type: 'INPUT',
			value: '',
			defaultValue: '',
			rules: {
				required: true,
				pattern: {
					value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, //email pattern
				}
			}
		},
	]
}

/**
 * 
 * ForgetPasswordForm --> ForgetPasswordForm
 */
export const ForgetPasswordForm = () => {
	return [
		{
			key: '1',
			span: 24,
			disabled: false,
			name: 'username',
			label: 'เลขประจำตัวผู้เสียภาษี',
			type: 'INPUT',
			value: '',
			defaultValue: '',
			rules: { required: true }
		},
		{
			key: '2',
			span: 24,
			disabled: false,
			name: 'email',
			label: 'อีเมลสำหรับส่งรหัสผ่านใหม่',
			type: 'INPUT',
			value: '',
			defaultValue: '',
			rules: {
				required: true,
				pattern: {
					value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, //email pattern
				}
			}
		},
	]
}

/**
 * 
 * registerRecheckCustomerForm --> registerRecheckCustomerForm
 */
 export const registerRecheckCustomerForm = () => {
		return [
			{
				key: '1',
				span: 24,
				disabled: false,
				name: 'idCard',
				label: 'เลขประจำตัวผู้เสียภาษี',
				type: 'INPUT',
				value: '',
				defaultValue: '',
				rules: {
					required: true,
					pattern: {
						message: 'โปรดระบุเป็นตัวเลขเท่านั้น',
						value: /^[0-9]*$/, //number pattern
					},
					maxLength: {
						value: 15,
						message: 15
					}
				}
			},
		]
}

/**
 * 
 * registerForm --> registerForm
 */
 export const registerForm = () => {
	return [
		{
			key: '1',
			span: 24,
			disabled: false,
			name: 'idCard',
			label: 'เลขประจำตัวผู้เสียภาษี',
			type: 'INPUT',
			value: '',
			defaultValue: '',
			rules: {
				required: true,
				pattern: {
					message: 'โปรดระบุเป็นตัวเลขเท่านั้น',
					value: /^[0-9]*$/, //number pattern
				},
				maxLength: {
					value: 15,
					message: 15
				}
			}
		},
		{
			key: '2',
			span: 24,
			disabled: false,
			name: 'displayName',
			label: 'ชื่อโปรไฟล์ (สำหรับแสดงบนเว็บเท่านั้น)',
			type: 'INPUT',
			value: '',
			defaultValue: '',
			rules: {
				required: true,
				// maxLength: {
				// 	value: 100,
				// 	message: 100
				// },
				// minLength: {
				// 	value: 5,
				// 	message: 5
				// },
			}
		},
		{
			key: '3',
			span: 24,
			disabled: false,
			name: 'password',
			label: 'สร้างรหัสผ่าน',
			type: 'INPUT_PASSWORD',
			value: '',
			defaultValue: '',
			rules: {
				required: true,
				maxLength: {
					value: 15,
					message: 15
				},
				minLength: {
					value: 6,
					message: 6
				},
			}
		},
		{
			key: '4',
			span: 24,
			disabled: false,
			name: 'rePassword',
			label: 'ยืนยันรหัสผ่าน',
			type: 'INPUT_PASSWORD',
			value: '',
			defaultValue: '',
			rules: {
				required: true,
				maxLength: {
					value: 15,
					message: 15
				},
				minLength: {
					value: 6,
					message: 6
				},
			}
		},
	]
}

/**
 * 
 * EditManageUserForm --> แก้ไขสิทธิ์ผู้ใช้
 */
export const EditManageUserForm = () => {
	return [
		{
			key: '1',
			span: 24,
			disabled: false,
			name: 'username',
			label: 'ชื่อผู้ใช้งาน',
			type: 'INPUT',
			value: '',
			defaultValue: '',
			rules: { required: true }
		},
		{
			key: '2',
			span: 24,
			disabled: false,
			name: 'password',
			label: 'รหัสผ่าน',
			type: 'INPUT_PASSWORD',
			value: '',
			defaultValue: '',
			rules: {
				required: true,
				maxLength: {
					value: 15,
					message: 15
				},
				minLength: {
					value: 6,
					message: 6
				},
			}
		},
		{
			key: '3',
			span: 24,
			disabled: false,
			name: 'role',
			label: 'Role',
			type: 'SELECT',
			value: '',
			defaultValue: '',
			rules: { required: true }
		},
		{
			key: '4',
			span: 24,
			disabled: false,
			name: 'status',
			label: 'สถานะ',
			type: 'SELECT',
			value: '',
			defaultValue: '',
			rules: { required: true }
		},
	]
}


/**
 * 
 * EditMailUserForm --> บันทึกอีเมลจัดส่งเอกสาร
 */
export const EditMailUserForm = () => {
	return [
		{
			key: '1',
			span: 24,
			disabled: false,
			name: 'email',
			label: 'อีเมล',
			type: 'INPUT',
			rules: {
				required: true,
				pattern: {
					value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, //email pattern
				}
			}
		},
	]
}

/**
 * 
 * EditRemarkDocumentForm --> บันทึกหมายเหตุเอกสาร
 */
export const EditRemarkDocumentForm = () => {
	return [
		{
			key: '1',
			span: 24,
			disabled: false,
			name: 'remark',
			label: 'หมายเหตุ',
			type: 'INPUT',
			rules: {
				required: true,
			}
		},
	]
}

/**
 * 
 * Dashboard --> dashboard
 */
export const DashboardForm = ({ optionYear }) => {
	return [
		{
			key: '1',
			span: 6,
			disabled: false,
			name: 'year',
			label: 'ปี',
			type: 'SELECT',
			value: '',
			options: optionYear,
			defaultValue: (new Date()).getFullYear(),
			rules: { required: true }
		},
	]
}

/**
 * 
 * ManageUserSearchForm --> จัดการสิทธิ์ผู้ใช้
 */
export const ManageUserSearchForm = ({ optionActiveStatus }) => {
	return [
		{
			key: '1',
			span: 6,
			disabled: false,
			name: 'displayName',
			label: 'ชื่อโปรไฟล์',
			type: 'INPUT',
			value: '',
			defaultValue: '',
		},
	]
}

/**
 * 
 * MissingDocScene --> เอกสารตกหล่น
 */
export const MissingDocSearchForm = ({ defaultValue, optionYear, optionMonth, onChangeDocType, optionDocType, optionDocTypeCompany }) => {
	return [
		{
			key: '1',
			span: 6,
			disabled: false,
			name: 'year',
			label: 'ปี',
			type: 'SELECT',
			value: '',
			options: optionYear,
			defaultValue: defaultValue.year || '',
			rules: { required: true }
		},
		{
			key: '2',
			span: 6,
			disabled: false,
			name: 'month',
			label: 'เดือน',
			type: 'SELECT',
			options: optionMonth,
			value: '',
			defaultValue: defaultValue.month || '',
			rules: { required: true }
		},
		{
			key: '3',
			span: 6,
			disabled: false,
			name: 'docType',
			label: 'ประเภทเอกสารหลัก',
			type: 'SELECT',
			onSelect: onChangeDocType,
			options: optionDocType || [],
			value: '',
			defaultValue: defaultValue.docType || '',
			rules: { required: true }
		},
		{
			key: '4',
			span: 6,
			disabled: false,
			name: 'docTypeCompany',
			label: 'ประเภทเอกสารย่อย',
			type: 'SELECT',
			// onChangeCustom: onChangeTree,
			options: optionDocTypeCompany || [],
			value: '',
			defaultValue: '',
		},

		// {
		// 	key: '3',
		// 	span: 12,
		// 	disabled: false,
		// 	name: 'signCount',
		// 	label: '',
		// 	type: 'CHECKBOX',
		// 	value: '',
		// 	options:[{id: 'แสดงเฉพาะจำนวนการ Sign ที่มากกว่า 1 ครั้ง', value: ''}],
		// 	defaultValue: '',
		// 	style: { alignSelf: 'center' }
		// },
	]
}

/**
 * 
 * HistorySignDocScene --> ประวัติการ Sign เอกสาร
 */
 export const HistorySignDocSearchForm = ({ optionYear, optionMonth, onChangeDocType, optionDocType, optionDocTypeCompany, optionFileType, searchParams }) => {
	return [
		{
			key: '1',
			span: 4,
			disabled: false,
			name: 'year',
			label: 'ปี',
			type: 'SELECT',
			value: '',
			options: optionYear,
			defaultValue: searchParams.year || '',
			rules: { required: true },
		},
		{
			key: '2',
			span: 5,
			disabled: false,
			name: 'month',
			label: 'เดือน',
			type: 'SELECT',
			options: optionMonth,
			value: '',
			defaultValue: searchParams.month || '',
			rules: { required: true },
		},
		{
			key: '3',
			span: 5,
			disabled: false,
			name: 'docType',
			label: 'ประเภทเอกสารหลัก',
			type: 'SELECT',
			onSelect: onChangeDocType,
			options: optionDocType || [],
			value: '',
			defaultValue: '',
		},
		{
			key: '4',
			span: 5,
			disabled: false,
			name: 'docTypeCompany',
			label: 'ประเภทเอกสารย่อย',
			type: 'SELECT',
			// onChangeCustom: onChangeTree,
			options: optionDocTypeCompany || [],
			value: '',
			defaultValue: '',
		},
		{
			key: '5',
			span: 5,
			disabled: false,
			name: 'fileType',
			label: 'นามสกุลไฟล์',
			type: 'SELECT',
			// onChangeCustom: onChangeTree,
			options: optionFileType || [],
			value: '',
			defaultValue: '',
		},
		// {
		// 	key: '3',
		// 	span: 12,
		// 	disabled: false,
		// 	name: 'signCount',
		// 	label: '',
		// 	type: 'CHECKBOX',
		// 	value: '',
		// 	options:[{id: 'แสดงเฉพาะจำนวนการ Sign ที่มากกว่า 1 ครั้ง', value: ''}],
		// 	defaultValue: '',
		// 	style: { alignSelf: 'center' }
		// },
	]
}

/**
 * 
 * ZipXmlSearchForm --> zip xml
 */
export const ZipXmlSearchForm = ({ defaultValue }) => {
	return [
		{
			key: '1',
			span: 8,
			disabled: false,
			name: 'dateStart',
			label: 'วันที่เริ่มออกเอกสาร',
			type: 'DATE_PICKER',
			value: '',
			rules: { required: true },
			defaultValue: defaultValue.dateStart || '',
		},
		{
			key: '2',
			span: 8,
			disabled: false,
			name: 'dateEnd',
			label: 'ถึงวันที่',
			type: 'DATE_PICKER',
			value: '',
			rules: { required: true },
			defaultValue: defaultValue.dateEnd || '',
		},
	]
}


/**
 * 
 * ManageUserSearchForm --> จัดการสิทธิ์ผู้ใช้
 */
export const DocumentSearchForm = ({ defaultValue, optionDocType, optionDocTypeCompany, onChangeDocType, docTypeSelected }) => {
	return [
		{
			key: '1',
			span: 4,
			disabled: false,
			name: 'docType',
			label: 'ประเภทเอกสารหลัก',
			type: 'SELECT',
			onSelect: onChangeDocType,
			options: optionDocType.length > 0 ? [ {label: 'ทั้งหมด', value: ''}, ...optionDocType] : [],
			value: '',
			defaultValue: '',
		},
		{
			key: '2',
			span: 4,
			disabled: false,
			name: 'docTypeCompany',
			label: 'ประเภทเอกสารย่อย',
			type: 'SELECT',
			// onChangeCustom: onChangeTree,
			options: optionDocTypeCompany.length > 0 ? [ {label: 'ทั้งหมด', value: ''}, ...optionDocTypeCompany] : [],
			value: '',
			defaultValue: '',
		},
		{
			key: '3',
			span: 4,
			disabled: false,
			name: 'dateStart',
			label: 'วันที่เริ่มออกเอกสาร',
			type: 'DATE_PICKER',
			value: '',
			rules: { required: true },
			defaultValue: defaultValue.dateStart || '',
		},
		{
			key: '4',
			span: 4,
			disabled: false,
			name: 'dateEnd',
			label: 'วันที่สิ้นสุด',
			type: 'DATE_PICKER',
			value: '',
			rules: { required: true },
			defaultValue: defaultValue.dateEnd || '',
		},
		{
			key: '5',
			span: 4,
			disabled: false,
			name: 'docNo',
			label: 'เลขที่เอกสาร',
			type: 'INPUT',
			value: '',
			rules: { required: false },
			defaultValue: defaultValue.docNo || '',
		},
		{
			key: '6',
			span: 4,
			disabled: false,
			name: 'customer',
			label: 'ลูกค้า',
			type: 'INPUT',
			value: '',
			rules: { required: false },
			defaultValue: defaultValue.customer || '',
		},
	]
}

export const DocumentSearchFormExport = ({ defaultValue, optionDocType, optionDocTypeCompany, onChangeDocType, docTypeSelected }) => {
	return [
		{
			key: '1',
			span: 4,
			disabled: false,
			name: 'docType',
			label: 'ประเภทเอกสารหลัก',
			type: 'SELECT',
			onSelect: onChangeDocType,
			options: optionDocType.length > 0 ? [ {label: 'ทั้งหมด', value: ''}, ...optionDocType] : [],
			value: '',
			defaultValue: '',
		},
		{
			key: '2',
			span: 4,
			disabled: false,
			name: 'docTypeCompany',
			label: 'ประเภทเอกสารย่อย',
			type: 'SELECT',
			// onChangeCustom: onChangeTree,
			options: optionDocTypeCompany.length > 0 ? [ {label: 'ทั้งหมด', value: ''}, ...optionDocTypeCompany] : [],
			value: '',
			defaultValue: '',
		},
		{
			key: '3',
			span: 4,
			disabled: false,
			name: 'dateStart',
			label: 'วันที่เริ่มออกเอกสาร',
			type: 'DATE_PICKER',
			value: '',
			rules: { required: true },
			defaultValue: defaultValue.dateStart || '',
		},
		{
			key: '4',
			span: 4,
			disabled: false,
			name: 'dateEnd',
			label: 'วันที่สิ้นสุด',
			type: 'DATE_PICKER',
			value: '',
			rules: { required: true },
			defaultValue: defaultValue.dateEnd || '',
		},
		{
			key: '5',
			span: 4,
			disabled: false,
			name: 'docNo',
			label: 'เลขที่เอกสาร',
			type: 'INPUT',
			value: '',
			rules: { required: false },
			defaultValue: defaultValue.docNo || '',
		},
		{
			key: '6',
			span: 4,
			disabled: false,
			name: 'customer',
			label: 'ลูกค้า',
			type: 'INPUT',
			value: '',
			rules: { required: false },
			defaultValue: defaultValue.customer || '',
		},
	]
}

/**
 * 
 * ReceiptDocumentSearchForm --> รายการใบเสร็จรับเงิน
 */
 export const ReceiptDocumentSearchForm = ({ defaultValue, optionDocType, optionDocTypeCompany, onChangeDocType, docTypeSelected }) => {
	return [
		{
			key: '1',
			span: 4,
			disabled: true,
			name: 'docType',
			label: 'ประเภทเอกสารหลัก',
			type: 'SELECT',
			onSelect: onChangeDocType,
			options: optionDocType || [],
			value: 'receipt',
			defaultValue: 'receipt',
		},
		{
			key: '2',
			span: 4,
			disabled: false,
			name: 'docTypeCompany',
			label: 'ประเภทเอกสารย่อย',
			type: 'SELECT',
			// onChangeCustom: onChangeTree,
			options: optionDocTypeCompany.length > 0 ? [ {label: 'ทั้งหมด', value: ''}, ...optionDocTypeCompany] : [],
			value: '',
			defaultValue: '',
		},
		{
			key: '3',
			span: 4,
			disabled: false,
			name: 'dateStart',
			label: 'วันที่เริ่มออกเอกสาร',
			type: 'DATE_PICKER',
			value: '',
			rules: { required: true },
			defaultValue: defaultValue.dateStart || '',
		},
		{
			key: '4',
			span: 4,
			disabled: false,
			name: 'dateEnd',
			label: 'วันที่สิ้นสุด',
			type: 'DATE_PICKER',
			value: '',
			rules: { required: true },
			defaultValue: defaultValue.dateEnd || '',
		},
		{
			key: '5',
			span: 4,
			disabled: false,
			name: 'docNo',
			label: 'เลขที่เอกสาร',
			type: 'INPUT',
			value: '',
			rules: { required: false },
			defaultValue: defaultValue.docNo || '',
		},
		{
			key: '6',
			span: 4,
			disabled: false,
			name: 'customer',
			label: 'ลูกค้า',
			type: 'INPUT',
			value: '',
			rules: { required: false },
			defaultValue: defaultValue.customer || '',
		},
	]
}

/**
 * 
 * PurchaseHistorySearchForm --> ประวัติการสั่งซื้อ
 */
export const PurchaseHistorySearchForm = ({ disabledDate}) => {
	return [
		{
			key: '1',
			span: 6,
			disabled: false,
			name: 'dateStart',
			label: 'วันที่เริ่มออกเอกสาร',
			type: 'DATE_PICKER',
			disabledDate,
			value: '',
			defaultValue: '',
		},
		{
			key: '2',
			span: 6,
			disabled: false,
			disabledDate,
			name: 'dateEnd',
			label: 'วันที่สิ้นสุด',
			type: 'DATE_PICKER',
			value: '',
			defaultValue: '',
		},
	]
}

/**
 * 
 * ReceiptHistorySearchForm --> ใบเสร็จรับเงิน
 */
export const ReceiptHistorySearchForm = ({ disabledDate }) => {
	return [
		{
			key: '1',
			span: 6,
			disabled: false,
			disabledDate,
			name: 'dateStart',
			label: 'วันที่เริ่มออกเอกสาร',
			type: 'DATE_PICKER',
			value: '',
			defaultValue: '',
		},
		{
			key: '2',
			span: 6,
			disabled: false,
			disabledDate,
			name: 'dateEnd',
			label: 'วันที่สิ้นสุด',
			type: 'DATE_PICKER',
			value: '',
			defaultValue: '',
		},
	]
}